import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withLoader.Web";
import { withToastProps } from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
// @ts-ignore
import CryptoJS from "crypto-js";
import { SECRET_PASSPHRASE } from "../../../framework/src/Globals";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginAPICallID: string = "";
  invalidPasswordError: string = "";
  getDemoUserApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      email: window.localStorage.getItem("rememberMe")
        ? this.getDecryptedInformation().slice(
            0,
            this.getDecryptedInformation().indexOf(" ")
          )
        : "",
      password: window.localStorage.getItem("rememberMe")
        ? this.getDecryptedInformation().slice(
            this.getDecryptedInformation().indexOf(" ") + 1
          )
        : "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const pathname = window.location.pathname;
    if (pathname === "/demo") {
      this.getDemoUser();
    }
  }

  handleInput = (event: any) => {
    this.setState({
      [event?.target.name]: event.target.value,
    });
  };

  getDecryptedInformation = () => {
    return CryptoJS.AES.decrypt(
      window.localStorage.getItem("rememberMe"),
      SECRET_PASSPHRASE
    ).toString(CryptoJS.enc.Utf8);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.loginAPICallID ||
          apiRequestCallId === this.getDemoUserApiCallId
        ) {
          this.props.hideLoader();
          if (responseJson && responseJson.token) {
            const token = responseJson?.token;
            const adminUser = responseJson?.admin_user;
            if (token) {
              this.setState(
                {
                  token: token,
                },
                () => {
                  window.localStorage.setItem("admintoken", token);
                  window.localStorage.setItem(
                    "adminuser",
                    JSON.stringify(adminUser)
                  );
                  // this.props.showToast({
                  //   message: "LoggedIn successfully",
                  //   type: "success",
                  // });
                  // @ts-ignore
                  const currentPathName =
                    // @ts-ignore
                    this.props.history?.location?.state?.currentPathName || "/";
                  // @ts-ignore
                  const currentPathState =
                    // @ts-ignore
                    this.props.history?.location?.state?.currentPathState || {};
                  this.props.history.push(currentPathName, {
                    currentPathState,
                  });
                }
              );
            }
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage));
      var AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      if (AlertBodyMessage === "Invalid password") {
        this.invalidPasswordError = AlertBodyMessage;
        this.forceUpdate();
        return;
      }
      this.props.showToast({
        type: "error",
        message: AlertBodyMessage,
      });
    }
  }

  getDemoUser = () => {
    this.props.showLoader();
    let endPoint = `${configJSON.demoUserAPiEndPoint}`;
    const requestMessage = this.generateRequestMessage(endPoint, "GET");
    this.getDemoUserApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //Login Form
  handleSubmitLogin = (data: any) => {
    this.props.showLoader();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
