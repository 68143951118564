//@ts-nocheck
import React, { Component, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  withRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Layout from "../../studio-store-ecommerce-components/src/Layout";
import EmailTemplates from "../../admin-email-templates/src/EmailTemplates.web";
import EmailTemplatesCreate from "../../admin-email-templates/src/EmailTemplatesCreate/EmailTemplatesCreate.web";
import UserConfirmation from "../../studio-store-ecommerce-components/src/UserConfirmation/UserConfirmation";
import GlobalLoading from "../../studio-store-ecommerce-components/src/GlobalLoading/GlobalLoading";

const Dashboard: any = lazy(
  () => import("../../admin-dashboard/src/Dashboard.web")
);
const BrandingHome: any = lazy(
  () => import("../../admin-branding/src/Branding.web")
);

import Login from "../../admin-email-account-login/src/Login.web";
import ForgotPassword from "../../admin-email-account-login/src/ForgotPassword.web";
import ProtectedRoute from "./protectedRoute.web";

const CreateStaticPage: any = lazy(
  () => import("../../admin-static-pages/src/CreateStaticPage/CreateStaticPage.web")
);

const CourseSubject: any = lazy(
  () => import("../../admin-subjects/src/Subjects.web")
);

const Levels: any = lazy(() => import("../../admin-levels/src/Levels.web"));

const LevelAssign: any = lazy(
  () => import("../../admin-levels/src/LevelAssign/LevelAssign.web")
);

const StoreDetails: any = lazy(
  () => import("../../admin-store-details/src/StoreDetails.web")
);

const StaticPageTable: any = lazy(
  () => import("../../admin-static-pages/src/StaticTable/StaticPageTable.web")
);

const Courses = lazy(
  () => import("../../admin-courses/src/List/CourseList.web")
);
const CourseSettings = lazy(
  () => import("../../admin-courses/src/CourseSettings/CourseSettings.web")
);
const CourseDetailedSettings = lazy(
  () =>
    import(
      "../../admin-courses/src/CourseSettings/Utilities/DetailedSettings/DetailedSettings.web"
    )
);
const CourseProgress = lazy(
  () => import("../../admin-courses/src/CourseProgress/CourseProgress.web")
);
const CourseModuleSettings = lazy(
  () => import("../../admin-courses/src/ModuleSettings/ModuleSettings.web")
);

const Account: any = lazy(() => import("../../admin-account/src/Account.web"));
const AccountDetails: any = lazy(
  () => import("../../admin-account/src/AccountDetails/AccountDetails.web")
);

const Students: any = lazy(
  () => import("../../admin-students/src/Student.web")
);
const CreateStudent: any = lazy(
  () => import("../../admin-students/src/CreateStudent/CreateStudent.web")
);

const ProgressStudent: any = lazy(
  () => import("../../admin-courses/src/CourseProgress/Student/Student.web")
);

const Instructors: any = lazy(
  () => import("../../admin-instructors/src/Instructor.web")
);
const CreateInstructor: any = lazy(
  () =>
    import("../../admin-instructors/src/CreateInstructor/CreateInstructor.web")
);

const Notifications: any = lazy(
  () => import("../../admin-notifications/src/Notifications.web")
);

const CourseProgressModule: any = lazy(
  () => import("../../admin-courses/src/CourseProgress/CourseProgressModule.web")
);
const CourseProgressStudent: any = lazy(
  () => import("../../admin-courses/src/CourseProgress/Student/Student.web")
);

class WebRoutes extends Component {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { history } = this.props;
    let STORE_BASE_PATH_NAME = "/";
    const pathname = window.location.pathname;
    const token = localStorage.getItem("token");
    try {
      STORE_BASE_PATH_NAME =
        new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? "";
    } catch {}
    return (
      <>
      <Router
        history={history}
        basename={STORE_BASE_PATH_NAME}
        getUserConfirmation={(message, callback) =>
          UserConfirmation(message, callback)
        }
      >
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/demo" exact component={Login} />
          <Route path="/forgotPassword" exact component={ForgotPassword} />
          <Suspense fallback={<GlobalLoading />}>
            <Layout>
              <Switch>
                <ProtectedRoute
                  path="/website-and-emails/branding"
                  exact
                  component={BrandingHome}
                />
                <ProtectedRoute
                  path="/website-and-emails/static-pages/:id"
                  exact
                  component={CreateStaticPage}
                />
                <ProtectedRoute
                  path="/website-and-emails/static-pages"
                  exact
                  component={StaticPageTable}
                />
                <ProtectedRoute
                  path="/website-and-emails/email-templates"
                  exact
                  component={EmailTemplates}
                />
                <ProtectedRoute
                  path="/website-and-emails/email-templates/:id"
                  exact
                  component={EmailTemplatesCreate}
                />

                <ProtectedRoute path="/students" exact component={Students} />
                <ProtectedRoute
                  path="/students/:id"
                  exact
                  component={CreateStudent}
                />
                <ProtectedRoute
                  path="/business-settings/instructors"
                  exact
                  component={Instructors}
                />
                <ProtectedRoute
                  path="/business-settings/instructors/create"
                  exact
                  component={CreateInstructor}
                />
                <ProtectedRoute
                  path="/business-settings/instructors/:id"
                  exact
                  component={CreateInstructor}
                />
                <ProtectedRoute
                  exact
                  path="/business-settings/notifications"
                  component={Notifications}
                />
                <ProtectedRoute path="/courses" exact component={Courses} />
                <ProtectedRoute
                  exact
                  path="/courses/:id/progress"
                  component={CourseProgress}
                />

                <ProtectedRoute
                  path="/courses/:id/progress/module/:moduleId"
                  exact
                  component={CourseProgressModule}
                />

                <ProtectedRoute
                  path="/courses/:id/progress/student/:studentId"
                  exact
                  component={CourseProgressStudent}
                />

                <ProtectedRoute
                  path="/courses/:id/"
                  render={(props: any) => (
                    <CourseSettings {...props}>
                      {(parentProps: any) => {
                        const path = props.match.path;
                        //course, lesson, selectedType, moduleId, lessonId
                        return (
                          <>
                            <Route
                              exact
                              path={path + "settings"}
                              render={(props) => (
                                <CourseDetailedSettings
                                  {...props}
                                  {...parentProps}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={path}
                              render={(props) => (
                                <CourseModuleSettings
                                  {...props}
                                  {...parentProps}
                                />
                              )}
                            />
                          </>
                        );
                      }}
                    </CourseSettings>
                  )}
                />

                <ProtectedRoute
                  path="/business-settings/subjects"
                  exact
                  component={CourseSubject}
                />

                <ProtectedRoute
                  path="/course-progress/:id"
                  exact
                  component={CourseProgress}
                />
                <ProtectedRoute
                  path="/course-progress/:id"
                  exact
                  component={CourseProgress}
                />
                <ProtectedRoute
                  path="/course-progress-assignments/:id"
                  exact
                  component={CourseProgressModule}
                />

                <ProtectedRoute
                  path="/progress-student"
                  exact
                  component={ProgressStudent}
                />

                <ProtectedRoute
                  exact
                  path="/business-settings/levels"
                  component={Levels}
                />

                <ProtectedRoute
                  path="/business-settings/levels/:id"
                  exact
                  component={LevelAssign}
                />

                <ProtectedRoute
                  exact
                  path="/business-settings/store-details"
                  component={StoreDetails}
                />
                <ProtectedRoute
                  exact
                  path="/account/overview"
                  component={Account}
                />
                <ProtectedRoute
                  exact
                  path="/account/detail/"
                  component={AccountDetails}
                />

                <ProtectedRoute path="/" exact component={Dashboard} />
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Layout>
          </Suspense>
        </Switch>
      </Router>
      </>
    );
  }
}

export default withRouter(WebRoutes);
