//@ts-nocheck
import React, { useCallback } from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import "./headerToastContext.css";
import BuilderLogo from "./Builder_Studio_Store.svg";
import SuccessIcon from "./tick.svg";
import ErrorIcon from "./error.png";
import LockIcon from "./lock.png";
import { isUserDemoAccount } from "../../../../framework/src/BlockComponent";

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}
export type ToastProps = {
  message: string;
  open?: boolean;
  onClose: Function;
  type: "success" | "error" | "info-lock";
  autoHideDuration?: number;
};

const getWhiteScreenProps = (type: any) =>
  type === "whitescreen"
    ? {
        backgroundColor: "white",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: "fixed",
      }
    : {};

export const Toast = (props: ToastProps) => {
  const { open, onClose, message, type, autoHideDuration = 5000 } = props;
  const { openToast } = React.useContext(DesktopToastContext);

  const handleClose = (event: any, reason: string) => {
    onClose(event, reason);
  };

  const saveChanges = () => {
    if (isUserDemoAccount()) {
      return openToast({
        autoHideDuration: 10000,
        type: "info-lock",
        message:
          "You can’t save this changes because you logged in as a demo user.",
      });
    }
    const requestMessage = new Message(
      getName(MessageEnum.ActionMessageFromToaster)
    );
    requestMessage.addData(
      getName(MessageEnum.ActionMessageFromToasterMessage),
      "SAVECHANGES"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    handleClose({}, "");
  };

  const discardChanges = () => {
    const requestMessage = new Message(
      getName(MessageEnum.ActionMessageFromToaster)
    );
    requestMessage.addData(
      getName(MessageEnum.ActionMessageFromToasterMessage),
      "DISCARDCHANGES"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    handleClose({}, "");
  };

  const renderMessage = () => {
    let displayedMessage = message;
    if (!message) {
      if (type === "success")
        displayedMessage = "Your data recorded successfully";
      if (type === "error")
        displayedMessage = `Sorry your data couldn't recorded`;
    }
    if (type === "info-lock") {
      displayedMessage = "";
    }
    return (
      <div
        className="snackbar-message-box"
        style={{ width: type ? "95vw" : "inherit" }}
      >
        <div className="snackbar-logo">
          <img src={BuilderLogo} alt="Builder Logo" />
        </div>
        <div className="snackbar-message">
          <div>
            {type === "success" && (
              <div>
                <img
                  className="snackbar-message-icon"
                  src={SuccessIcon}
                  alt="Success"
                />
                <span className="snackbar-message-icon-message">Success!</span>
              </div>
            )}
            {type === "error" && (
              <div>
                <img
                  className="snackbar-message-icon"
                  src={ErrorIcon}
                  alt="Error"
                />
                <span className="snackbar-message-icon-message">Error!</span>
              </div>
            )}
            {type === "info-lock" && (
              <div className="snackbar-message-info-lock">
                <img
                  className="snackbar-message-icon"
                  src={LockIcon}
                  alt="Lock"
                />
                <span className="snackbar-message-icon-message">{message}</span>
              </div>
            )}
          </div>
          <div className="snackbar-message-content">{displayedMessage}</div>
        </div>
      </div>
    );
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{
        top: 0,
        right: 0,
        ...getWhiteScreenProps(type),
      }}
      open={open}
      autoHideDuration={
        !!type && type !== "whitescreen" ? autoHideDuration : null
      }
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      ClickAwayListenerProps={(data) => {
        console.log(data);
      }}
    >
      <SnackbarContent
        style={{
          backgroundColor: "#3C3E49",
          borderRadius: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "11px 32px",
          minWidth: "calc(100vw)",
          message: {
            width: "100%",
          },
          ...getWhiteScreenProps(type),
        }}
        message={renderMessage()}
        action={
          !type && (
            <React.Fragment>
              <Button
                color="primary"
                style={{ color: "#fff", paddingRight: 24 }}
                variant="text"
                onClick={discardChanges}
              >
                Discard changes
              </Button>
              <Button color="primary" variant="contained" onClick={saveChanges}>
                Save changes
              </Button>
            </React.Fragment>
          )
        }
      />
    </Snackbar>
  );
};

export const DesktopToastContext = React.createContext({});

const DefaultOptions = {
  ignoreClickAway: true,
  autoHideDuration: 5000,
};

export const HeaderToastProvider: React.FunctionComponent = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState(DefaultOptions);

  function checkRouteChanges() {
    const routeStatus =
      localStorage.getItem("routeChanged") &&
      localStorage.getItem("routeChanged") === "true";

    if (routeStatus) {
      setDialogOpen(false);
      localStorage.removeItem("routeChanged");
    }
  }

  React.useEffect(() => {
    let routeInterval;
    if (dialogOpen) {
      routeInterval = setInterval(checkRouteChanges, 500);
    } else {
      routeInterval && clearInterval(routeInterval);
    }
    return () => {
      routeInterval && clearInterval(routeInterval);
      localStorage.removeItem("routeChanged");
    };
  }, [dialogOpen]);

  const openToast = (props: any = {}) => {
    if (!_.isEqual(props, options)) setOptions({ ...DefaultOptions, ...props });
    if (!dialogOpen) {
      setDialogOpen(true);
    }
  };

  const hideToast = React.useCallback(
    (event, reason) => {
      if (options?.ignoreClickAway && reason === "clickaway" && !options?.type)
        return;
      setDialogOpen(false);
    },
    [options]
  );

  return (
    <DesktopToastContext.Provider
      value={{
        openToast,
        hideToast,
        toastOptions: { ...options, status: dialogOpen },
      }}
    >
      <Toast open={dialogOpen} onClose={hideToast} {...options} />
      {children}
    </DesktopToastContext.Provider>
  );
};
